<template>
  <div>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="date" label="序号" width="100px">
      </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="name" label="片区"> </el-table-column>
      <el-table-column prop="name" label="职位"> </el-table-column>
      <el-table-column prop="name" label="业绩笔数"> </el-table-column>
      <el-table-column prop="name" label="有效业绩(元)"> </el-table-column>
      <el-table-column prop="name" label="提成比例"> </el-table-column>
      <el-table-column prop="name" label="本月提成(元)"> </el-table-column>
      <el-table-column prop="name" label="本月冲正(笔)"> </el-table-column>
      <el-table-column prop="name" label="本月冲正(元)"> </el-table-column>
      <el-table-column prop="name" label="合计提成(元)"> </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button
            class="table-btn grant-btn"
            size="mini"
            @click="handleGrant(scope.$index, scope.row)"
            >发放</el-button
          >
          <el-button
            size="mini"
            class="table-btn info-btn"
            @click="handleInfo(scope.$index, scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <statistical-info-dialog
      @down="down"
      :showDialog="showDialog"
    ></statistical-info-dialog>
  </div>
</template>

<script>
import StatisticalInfoDialog from "./statisticalInfoDialog.vue";
export default {
  components: {
    StatisticalInfoDialog,
  },
  data() {
    return {
      //  显示详情对话框
      showDialog: false,
      //  表格数据
      tableData: [
        {
          date: "1",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "3",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "4",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
    };
  },
  methods: {
    // 发放按钮点击事件
    handleGrant(index, row) {
      console.log(index, row);
    },
    // 详情按钮点击事件
    handleInfo(index, row) {
      console.log(index, row);
      this.showDialog = true;
    },
    //
    down() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss">
.performance-statistics-table {
  .has-gutter {
    tr {
      th {
        background-color: $index-button-color;
        color: #fff;
        text-align: center;
      }
    }
  }
  .el-table__body {
    td {
      text-align: center;
    }
  }
}
.table-btn {
  width: 80px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
}
.info-btn {
  background-color: $index-button-color;
}
.grant-btn {
  background-color: $index-buttonPor-color;
}
</style>
