<template>
  <el-dialog
    class="statistical-info-dialog"
    title="月份详情"
    :visible.sync="showDialog"
    width="60%"
    :before-close="handleClose"
  >
    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
export default {
  name: "statistical-info-dialog",
  props: {
    showDialog: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit("down");
    },
  },
};
</script>

<style lang="scss">
.el-dialog__header {
  background-color: $index-buttonPor-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 20px;
  span,
  i {
    color: #fff !important;
  }
}
</style>
