<template>
  <div class="preformance-container">
    <el-card class="preformance-content">
      <el-container>
        <!-- 业绩统计头部 -->
        <el-header>
          <el-row :gutter="10" type="flex">
            <!-- 功能左 -->
            <el-col :span="6">
              <el-button class="all">全部</el-button>
              <el-select v-model="value8" filterable placeholder="片区">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                placeholder="请输入内容"
                prefix-icon="el-icon-search"
                v-model="search"
              >
              </el-input>
            </el-col>
            <!-- 选择月份 -->
            <el-col :span="18">
              <el-button
                :class="monthColor(item)"
                v-for="item in 12"
                :key="item"
                @click="monthClick(item)"
                >{{ item }}月份</el-button
              >
            </el-col>
          </el-row>
        </el-header>
        <!-- 业绩统计内容主体 -->
        <el-main>
          <table-com class="performance-statistics-table"></table-com>
          <pagination-com></pagination-com>
        </el-main>
      </el-container>
    </el-card>
  </div>
</template>

<script>
import TableCom from "../components/performance-statistics/tableCom.vue";
import paginationCom from "../components/paginationCom.vue";
export default {
  name: "preformance-container",
  components: {
    TableCom, paginationCom
  },
  data() {
    return {
      search: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value8: "",
      // 按钮默认背景颜色索引
      btnIndex: 1,
      currentPage4: 1,
    };
  },
  computed: {
    monthColor() {
      return (i) => {
        return {
          color: this.btnIndex === i,
        };
      };
    },
  },
  methods: {
    // 月份按钮点击事件
    monthClick(i) {
      this.btnIndex = i;
    },
    // 分页器
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    // 分页器
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.preformance-container {
  padding: 30px;
  .el-card__body {
    padding: 0px !important;
  }
}
.el-header {
  height: 40px !important;
  padding: 0px;
}
.el-card {
  height: 100%;
}
.el-col{
  padding-left: 0px !important;
}
.el-input {
  width: 210px;
}
.el-button{
  border: none;
}
.el-select {
  margin: 0px 10px;
  width: 120px;
}
.all {
  background-color: $index-button-color;
  color: #ffffff;
  width: 80px;
}
.color {
  background: #ff7079;
  color: #fff;
}
.performance-statistics-table {
  margin-bottom: 20px;
}
.el-pagination {
  height: 40px;
  line-height: 40px;
}
</style>
